<template>
  <div class="ymms-selector">
    <select v-model.number="currentSelection.Year">
      <option disabled hidden value="0">Year</option>
      <option v-for="year in allYears" :key="year">{{ year }}</option>
    </select>
    <select :disabled="!allMakes.length" v-model="currentSelection.Make">
      <option disabled hidden value="">Make</option>
      <option v-for="make in allMakes" :key="make">{{ make }}</option>
    </select>
    <select :disabled="!allModels.length" v-model="currentSelection.Model">
      <option disabled hidden value="">Model</option>
      <option v-for="model in allModels" :key="model">{{ model }}</option>
    </select>
    <select
      :disabled="!allSeries.length"
      v-model="currentSelection.Trim"
      @change="selectVehicle()"
    >
      <option disabled hidden value="">Series</option>
      <option v-for="series in allSeries" :key="series" :value="series">
        {{ series || "Base" }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["selectedVehicle"],
  data() {
    let currentSelection = {
      Year: 0,
      Make: "",
      Model: "",
      Trim: "",
      YMMS_Desc: ""
    };

    if (this.selectedVehicle) {
      currentSelection = this.selectedVehicle;
    }
    return { currentSelection };
  },
  model: {
    prop: "selectedVehicle",
    event: "change",
  },
  methods: {
    selectVehicle() {
      const vehicle = this.allYmms.filter((x) => x.Year === this.currentSelection.Year && x.Make === this.currentSelection.Make && x.Model === this.currentSelection.Model && x.Trim === this.currentSelection.Trim)[0]
      this.$emit("change", vehicle);
    },
  },
  computed: {
    allYmms() {
      return this.$store.state.allYmms;
    },
    allYears() {
      return [...new Set(this.allYmms.map((x) => x.Year))].sort(
        (a, b) => b - a
      );
    },
    allMakes() {
      return [
        ...new Set(
          this.allYmms
            .filter((x) => x.Year === this.currentSelection?.Year)
            .map((x) => x.Make)
        ),
      ].sort();
    },
    allModels() {
      return [
        ...new Set(
          this.allYmms
            .filter(
              (x) =>
                x.Year === this.currentSelection?.Year &&
                x.Make === this.currentSelection?.Make
            )
            .map((x) => x.Model)
        ),
      ].sort();
    },
    allSeries() {
      return [
        ...new Set(
          this.allYmms
            .filter(
              (x) =>
                x.Year === this.currentSelection?.Year &&
                x.Make === this.currentSelection?.Make &&
                x.Model === this.currentSelection?.Model
            )
            .map((x) => x.Trim)
        ),
      ].sort();
    },
  },
};
</script>
<style lang="scss" scoped>
select {
  width: 200px;
  display: block;
}
</style>
